<template>
  <div class="space"></div>
</template>

<script>
export default {
  name: "VSpace",
  props: {
    big: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.space
  transform: translate3d(0,0,0)
  width: 100%
  height: var(--gutter)
  position: relative
  background-color: white
  z-index: 10
</style>
