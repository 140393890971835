<template>
  <v-main class="app">
    <v-header style="filter: invert(1);"/>
    <!-- header -->
    <v-cover
    :src="isMobile ? 'https://res.cloudinary.com/neuvieme-page/video/upload/f_auto/v1670419767/app-eoy/videos/1_mobile_eoy.mp4' : 'https://res.cloudinary.com/neuvieme-page/video/upload/v1670434827/app-eoy/videos/original_video.mp4'"
      logoImage="/desktop/logo-drop-shadow.png" :logo-image-width="['50%', '90%']" :ratio="16 / 9"
      :fetchPriority="'high'"
      :muteDisplay="true"
      :lazy="'eager'"/>
    <!-- header -->
    <v-space :style="{ height: 'var(--module-spacing-y-eoy)' }"/>

    <v-duo :style="{ padding: isMobile ? '0 62px' : '0 10vw' }" :gutter-size="'5.2vw'" :slides="[
      {
        mediaLink: 'https://www.ysl.com/en-en/tote-bags/icare-maxi-shopping-bag-in-quilted-lambskin-698651AAANG1000.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        src:
          '/edito/WW Censored/1_SLE141022_1325_RGB.jpg',
        ratio: 2000 / 3000,
      },
      {
        src:
        '/edito/WW Censored/2_SLE141022_1255_RGB.jpg',
        ratio: 2000 / 3000,
        mediaLink: 'https://www.ysl.com/en-en/search?cgid=rings-women&utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      },
    ]"></v-duo>

    <v-space :style="{ height: 'var(--module-spacing-y-eoy)' }"/>

    <v-solo :style="{ padding: isMobile ? '0 32px' : '0 31.25vw' }" :slides="[
      {
        mediaLink: 'https://www.ysl.com/en-en/holiday-arrivals?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
        src:
        '/edito/WW Censored/3_SLE141022_0707_RGB.jpg',
        ratio: 2000 / 3000,
      },
    ]"></v-solo>

    <v-space :style="{ height: isMobile ? '64px' : '210px' }"/>

    <v-duo :nocolumns="true" :style="{ padding: isMobile ? '0 5.33333vw' : '0 27vw' }" :gutter-size="'4px'" :slides="[
      {
        mediaLink: 'https://www.ysl.com/en-en/tote-bags/icare-maxi-shopping-bag-in-quilted-lambskin-698651AAANG1000.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        src:
          '/packshots/WW Censored/Dyptique/1_698651AAANG1000_A.jpg',
        ratio: 2000 / 3260,
      },
      {
        src:
          '/packshots/WW Censored/Dyptique/2_736443Y99562336_A.jpg',
        ratio: 2000 / 3260,
        mediaLink: 'https://www.ysl.com/en-en/sunglasses/sl-558-713741Y99011034.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
      },
    ]"></v-duo>

    <v-space :style="{ height: isMobile ? '64px' : '210px' }"/>

    <v-cover
      :src="isMobile ? '/videos/3_mobile_eoy.mp4' : '/videos/3_eoy.mp4'"
      :muteDisplay="true"
      :ratio="(isMobile) ? 9/16 : 16/9" :fetchPriority="'high'"
      :lazy="'lazy'"/>

    <v-space :style="{ height:isMobile ? '64px' : '210px' }"/>

    <!-- 3 SLIDER COMPONENT -->
    <v-column-slideshow :style="{ padding: isMobile ? '0 105px' : '0 9.8vw' }" :slideshows="[[
      {
        mediaLink: 'https://www.ysl.com/en-en/cuffs-and-bracelets/thin-textured-bangle-in-metal-715549Y15008204.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        src:
          '/packshots/WW Censored/Tryptique Bracelets/1_715549Y15008204_A.jpg',
        ratio: 2000 / 3260,
      },
    ], [
      {
        mediaLink: 'https://www.ysl.com/en-en/cuffs-and-bracelets/tornado-crater-cuff-bracelet-in-metal-714975Y15008204.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        src:
          '/packshots/WW Censored/Tryptique Bracelets/2_714975Y15008204_A.jpg',
        ratio: 2000 / 3260,
      },
    ], [
      {
        mediaLink: 'https://www.ysl.com/en-en/cuffs-and-bracelets/bumpy-textured-cuff-bracelet-in-metal-716598Y15008204.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        src:
          '/packshots/WW Censored/Tryptique Bracelets/3_716598Y15008204_A.jpg',
        ratio: 2000 / 3260,
      },
    ]]"/>

    <v-space :style="{ height:isMobile ? '64px' : '160px' }"/>

    <v-solo :style="{ padding: isMobile ? '0 8.76vw' : '0 31.25vw' }" :slides="[
      {
        mediaLink: 'https://www.ysl.com/en-en/search?cgid=new-arrivals-jewellery-women&utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
        src:
        '/edito/WW Censored/4_SLE141022_0892_RGB.jpg',
        ratio: 2000 / 3000,
      },
    ]"></v-solo>

    <v-space :style="{  height: isMobile ? '64px' : '210px' }"/>

    <!-- MISSING big SLIDER COMPONENT -->
    <div class="slideshow__custom">
      <v-swiper :freeMode="true" :mouseWheel="!isMobile" :spaceBetween="4" :slidesView="isMobile ? 2 : 4" :slides="[
        {
          mediaLink: 'https://www.ysl.com/en-en/outerwear/coat-in-animal-free-fur-718539Y4F852503.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
          src:
            '/packshots/WW Censored/Grand Slider/1_718539Y4F852503_A.jpg',
          ratio: 2000 / 3260,
        },
        {
          src:
            '/packshots/WW Censored/Grand Slider/2_715627Y15268368_A.jpg',
          ratio: 2000 / 3260,
          mediaLink: 'https://www.ysl.com/en-en/rings/rhinestone-egg-ring-in-metal-715627Y15268368.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        },
        {
          src:
            '/packshots/WW Censored/Grand Slider/3_7175741UU001720_A.jpg',
          ratio: 2000 / 3260,
          mediaLink: 'https://www.ysl.com/en-en/high-heel-sandals/lila-sandals-in-crepe-satin-7175741UU001720.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        },
        {
          src:
            '/packshots/WW Censored/Grand Slider/4_724392Y76CV2020_A.jpg',
          ratio: 2000 / 3260,
          mediaLink: 'https://www.ysl.com/en-en/long-dress-in-viscose-724392Y76CV2020.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        },
        {
          mediaLink: 'https://www.ysl.com/en-en/rings/rhinestone-edge-egg-ring-in-metal-712408Y15269577.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
          src:
            '/packshots/WW Censored/Grand Slider/5_712408Y15269577_A.jpg',
          ratio: 2000 / 3260,
        },
        {
          src:
            '/packshots/WW Censored/Grand Slider/6_7175721UU001000_A.jpg',
          ratio: 2000 / 3260,
          mediaLink: 'https://www.ysl.com/en-en/high-heel-sandals/cassie-sandals-in-crepe-satin-7175721UU001000.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        }
      ]"/>
    </div>

    <v-space :style="{ height: isMobile ? '64px' : '210px' }"/>


    <v-solo :style="{ padding: isMobile ? '0 8.76vw' : '0 31.25vw' }" :slides="[
      {
        mediaLink: 'https://www.ysl.com/en-en/sl-596-dune-736443Y99562336.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing ',
        src:
        '/edito/WW Censored/5_SLE141022_0194_RGB.jpg',
        ratio: 2000 / 3000,
      },
    ]"></v-solo>

    <v-space :style="{ height: isMobile ? '64px' : '210px' }"/>

    <v-cover
      :src="isMobile ? '/videos/2_mobile_eoy.mp4' : '/videos/2_bag_eoy.mp4'"
      :muteDisplay="true"
      logoImage="/desktop/logo-drop-shadow.png" :logo-image-width="['50%', '90%']" :ratio="16 / 9"
      :fetchPriority="'high'"
      :lazy="'lazy'"/>
  </v-main>
</template>
<script>
import PageResizeMixin from '@ysl-modules-rtw/core/src/vue/mixins/resize-observer'

export default {
  mixins: [PageResizeMixin]
}
</script>
<style lang="sass">
.cover__logoImage
  aspect-ratio: 855/310

.header__content
  background-color: rgba(255, 255, 255, 0)

.slideshow__custom
  width: calc(100vw - 32px)
  margin-left: 32px

  @media screen and (min-width: 1024px) and (orientation: landscape)
    width: calc(100vw - 10vw)
    margin-left: 10vw

</style>
