<template>
  <div class="tri" :style="`--gutter-size: ${gutterSize}px`">
    <div v-for="(slide, i) in inverted ? slides.reverse() : slides" :key="i" :class="`tri__slide tri__slide__${i}`">
      <v-media
        class="cover__media"
        :src="slide.src"
        :style='{height: medium && isMobile ?  "": ""}'
        :lazy="slide.lazy"
        :srcset="slide.srcset"
        :sizes="slide.sizes"
        :ratio="slide.ratio"
        :media-link="slide.mediaLink"
        :contain="contain"
      />
    </div>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'

export default {
  name: 'VTri',
  components: {
    VMedia
  },
  props: {
    slides: {
      type: [Array, Object],
      required: true
    },
    gutters: {
      type: Boolean,
      default: false
    },
    gutterSize: {
      type: Number,
      default: 32,
      required: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    mediaLink: {
      type: String,
      default: ''
    },
    medium: {
      type: Boolean,
      default: false,
    },
    contain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768
    }
  }
}
</script>

<style lang="sass">
.tri
  transform: translate3d(0, 0, 0)
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: 1fr
  grid-column-gap: var(--gutter-size)
  grid-row-gap: 0px
  width: 100%
  box-sizing: border-box
  overflow: hidden
  position: relative
  background-color: white
  z-index: 10

  &__media
    height: 100%

  @media screen and (max-width: 768px)
    grid-template-columns: 1fr
    grid-row-gap: 16px

    .cover__media
      //height: 70vh

    .media__wrapper
      padding: 0 !important
</style>
