<template>
  <div
    class="solo"
  >
    <div v-for="(slide, i) in slides"  :key="i" :class="`solo__slide solo__slide__${i}`">
        <v-media
            class="cover__media"
            :src="slide.src"
            :lazy="slide.lazy"
            :srcset="slide.srcset"
            :sizes="slide.sizes"
            :ratio="slide.ratio"
            :media-link="slide.mediaLink"
        />
    </div>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'
export default {
  name: 'VSolo',
  components: {
    VMedia
  },
  props: {
    slides: {
      type: [Array, Object, String],
      required: true
    },
    gutters: {
      type: Boolean,
      default: false
    },
    mediaLink: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="sass" scoped>
.solo
  transform: translate3d(0, 0, 0)
  display: grid
  grid-template-columns: repeat(1, 1fr)
  grid-template-rows: 1fr
  grid-column-gap: var(--gutter-size)
  grid-row-gap: 0px
  width: 100%
  box-sizing: border-box
  overflow: hidden
  position: relative
  background-color: white
  z-index: 10

  &__media
    height: 100%

  @media screen and (max-width: 768px)
    grid-template-columns: 1fr
    grid-row-gap: 16px

    .cover__media
      //height: 70vh

    .media__wrapper
      padding: 0 !important

    
</style>
