<template>
  <!-- Tiny-Swiper -->
  <div class="carousel" :class="{
    'carousel--with-pagination': pagination,
  }">
    <div class="swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
          <v-media v-if="slide.src"
                   class="slideshow__media"
                   :src="slide.src" :srcset="slide.srcset" :sizes="slide.sizes" :link="slide.link"
                   :media-link="slide.mediaLink" :ratio="slide.ratio"
          />
        </div>
      </div>
    </div>
    <div v-if="pagination" ref="swiperPagination" class="swiper-pagination"></div>
  </div>
</template>

<script>
function loadScript(src) {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script')
    s.src = src
    s.id = 'swiper'
    s.onload = resolve
    s.onerror = reject
    document.head.appendChild(s)
  })
}

function loadCSS(href, _id, once = false) {
  return new Promise((resolve, reject) => {
    if (document.getElementById(_id) && once) {
      return resolve()
    }
    const link = document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('type', 'text/css')
    link.setAttribute('href', href)
    link.setAttribute('id', _id)
    document.head.appendChild(link)
    link.onload = () => {
      return resolve()
    }
    link.onerror = reject
  })
}

export default {
  name: 'VSwiper',
  props: {
    slides: {
      type: [Array, Object],
      required: true,
    },
    slidesView: {
      type: Number,
      default: 4,
      required: false
    },
    freeMode: {
      type: Boolean,
      default: false,
      required: false
    },
    spaceBetween: {
      type: Number,
      default: 0,
      required: false
    },
    loop: {
      type: Boolean,
      default: false,
      required: false
    },
    mouseWheel: {
      type: Boolean,
      default: false,
      required: false
    },
    pagination: {
      type: Boolean,
      default: false,
      required: false
    },
  }
  ,
  async mounted() {
    await new Promise(resolve => setTimeout(resolve, 0))
    await loadScript('https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.js', 'swiper')
    await loadCSS('https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.css', 'swiper')

    let swiper = new Swiper(this.$refs.swiper, {
      slidesPerView: this.slidesView,
      freeMode: this.freeMode,
      mousewheel: this.mouseWheel,
      sicky: true,
      spaceBetween: this.spaceBetween,
      minimumVelocity: 0.03,
      pagination: {
        el: this.$refs.swiperPagination,
        type: 'bullets',
      },
      paginationClickable: true
    });
  }
}
</script>

<style lang="sass">
/* basic */

.swiper-pagination
  position: absolute
  bottom: -3px !important / / should be fine as is, but adjust to your liking

.swiper-pagination-bullet
  border: 1px solid black !important
  background: transparent !important
  width: 6px !important
  height: 6px !important

.swiper-pagination-bullet-active
  background: black !important

.carousel
  position: relative


.swiper-wrapper
  transition-timing-function: cubic-bezier(.15, .67, .21, .97) !important
  will-change: transform !important

.swiper
  position: relative

.media
  pointer-events: auto

.swiper-container
  width: calc(100% - 10vw)
  margin-left: 10vw

.swiper-wrapper
  padding: 0
  margin: 0
  width: 100%
  height: 100%

.swiper-slide
  position: relative
  display: flex
  flex-shrink: 0
  justify-content: center
  width: 100%
  height: 100%
  font-size: 18px
  align-items: center
  cursor: grab

.swiper-slide img
  display: block
  width: 100%
  height: 100%
  object-fit: cover
  object-position: top

/* image lazyload */
.swiper-lazy
  display: block
  width: 100%
  height: 100%
  object-fit: cover
  opacity: 0
  transition: all ease 300ms

.swiper-lazy-preloader
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.swiper-lazy-loaded
  opacity: 1


.swiper-lazy-preloader::after
  display: block
  content: ""
  position: absolute
  left: 50%
  top: 50%
  margin: -15px 0 0 -15px
  width: 30px
  height: 30px
  border: 2px solid rgba(0, 0, 0, 0.88)
  border-radius: 50%
  border-bottom-color: transparent
  transform-origin: 50%

</style>
