<template>
  <section class="columnSlideshow" :style="`--count: ${slideshows.length};--gap:${gutterSize}px;`">
    <v-media v-for="(slides) in slideshows" :key="slides[0].src" class="columnSlideshow__item" :src="slides[0].src"
             :ratio="slides[0].ratio" :mediaLink="slides[0].mediaLink"/>
  </section>
</template>

<script>
export default {
  name: 'VColumnSlideshow',
  props: {
    slideshows: {
      type: Array,
      required: true
    },
    gutterSize: {
      type: Number,
      default: 85,
      required: false,
    }
  }
}
</script>

<style lang="sass">
.columnSlideshow
  display: block

  .slideshow__bullets--outside
    margin-top: 32px

  .columnSlideshow__item
    margin-top: 4px
    width: 100%
    @media (min-width: 768px)
      &:not(:first-child)
        margin-left: 4px

  .columnSlideshow__item:first-child
    margin-top: 0

  .carousel--with-pagination
    padding-bottom: 40px

  @media (min-width: 768px)
    display: inline-flex
    flex-wrap: wrap

    .carousel--with-pagination
      padding-bottom: 46px

    .columnSlideshow__item
      margin-top: var(--gap)
      width: calc(33.33333% - 4px)

    .slideshow__bullets--outside
      margin-top: 38px

    .columnSlideshow__item
      margin-top: 0
</style>
