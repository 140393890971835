<template>
  <span class="cover" style="position:relative; z-index: 3;">
    <img v-if="logoImage" :src="logoImage" :style="{
      '--logo-image-width-small': logoImageWidth[0],
      '--logo-image-width-large': logoImageWidth[1]
    }"
      class="cover__logoImage" alt="Logo SL" />
    <div class="cover" :class="{
      'cover--fit': ratio === -1,
      'cover--gutters': gutters,
      'cover--innerspace': innerspace,
      'cover--sticky': sticky,
    }">
      <v-media class="cover__media" :class="{'cover__media--fullHeight': fullHeight}" :src="src" :lazy="lazy"
        :fetchPriority="fetchPriority" :srcset="srcset" :sizes="sizes" :ratio="ratio" :poster="poster" :contain="contain"
        :muteDisplay="muteDisplay" :media-link="mediaLink" />
    </div>
  </span>
</template>

<script>
import VMedia from './VMedia.vue'

export default {
  name: 'VCover',

  components: {
    VMedia,
  },

  props: {
    lazy: {
      type: String,
      required: false,
      default: 'lazy',
    },
    fetchPriority: {
      type: String,
      required: false,
      default: 'auto',
    },
    logo: {
      type: String,
      required: false,
    },
    src: {
      type: String,
      required: true,
    },
    srcset: {
      type: String,
      default: '',
    },
    sizes: {
      type: String,
      default: '',
    },
    ratio: {
      type: Number,
      default: 0,
    },
    poster: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    gutters: {
      type: Boolean,
      default: false,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    logoImage: {
      type: String,
      default: '',
    },
    /**
     * @param {Array<String>} LogoImageWidth — Set responsive logo-size (0: Mobile 1: Desktop) in percent.
     */
    logoImageWidth: {
      type: Array,
      default: () => ['50%', '70%']
    },
    innerspace: {
      type: Boolean,
      default: false,
    },
    mediaLink: {
      type: String,
      default: '',
    },
    fullHeight: {
      type: Boolean,
      default: true,
    },
    muteDisplay: {
      type: Boolean,
      default: false,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    muteVideo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass">
.cover
  // margin: var(--innergutter) 0
  position: relative
  background-color: white
  width: 100%
  display: block
  height: 100%

  &__logo
    width: 100%
    top: 50%
    left: 50%
    position: absolute
    display: block
    z-index: 2
    transform: translate(-50%, -50%)
    text-transform: uppercase
    text-align: center
    h1
      font-size: 17px
      font-weight: bold
      line-height: 14px
      color: white
      @media screen and (max-width: 1023px)
        font-size: 12px
        line-height: 11px
        letter-spacing: 0.04em
    p
      font-size: 11px
      font-weight: 400
      line-height: 14px
      color: white
      @media screen and (max-width: 1023px)
        font-size: 11px
        line-height: 11px
        letter-spacing: -0.01em

    @media screen and (min-width: 768px)
      max-width: 182px

  &__logoImage
    width: var(--logo-image-width-small)
    top: 50%
    left: 50%
    position: absolute
    display: block
    z-index: 10
    transform: translate(-50%, -50%)

    @media screen and (max-width: 768px)
      margin-left: auto
      margin-right: auto
      width: var(--logo-image-width-large)

  &--innerspace
    padding: 0 calc(var(--gutter))
    @media screen and (min-width: 768px)
      padding: 0 calc(var(--gutter) * 3)

  &--gutters
    padding: 0 var(--gutter)
    .media__wrapper
      margin: 0

  &--sticky
    position: fixed
    // position: -webkit-sticky
    top: 0
    display: block
    height: 100vh
    width: 100%

  &__media
    width: 100%

    &--fullHeight
      height: 100vh

      .media__wrapper
        padding-top: 0 !important
        height: 100%

  &--fit &__media
    height: 100%
</style>
