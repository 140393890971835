<template>
  <div class="slideshow" :class="{
    'slideshow--gutters': gutters,
    'slideshow--wrap': wrap,
    'slideshow--demi': demi,
    'slideshow--showmargin': showMargin,
  }" :style="{ '--slides': numberOfSlides }" @mousedown.prevent="swipeStart" @touchstart="swipeStart">
    <div class="slideshow__wrapper" :class="{ 'slideshow__wrapper--transition': swipeOrigin === null }"
      :style="{ '--translation': translation }" ref="wrapper">
      <div v-for="(slide, index) in slides" class="slideshow__slide" :style="{ '--slices': slide.slices || 1 }"
        :key="index">
        <v-media v-if="slide.src" :class="{ 'slideshow__media--interactive': !isActive }" class="slideshow__media"
          :src="slide.src" :srcset="slide.srcset" :sizes="slide.sizes" :link="slide.link" :share="slide.share"
          :tags="slide.tags" :media-link="slide.mediaLink" :instance="slide.instance" :ratio="slide.ratio"
          :poster="slide.poster" />
        <div v-if="gutters && slide.slices > 1" class="slideshow__slices">
          <div v-for="index in slide.slices - 1" class="slideshow__slice" :key="index"></div>
        </div>
        <p v-if="slide.caption" class="slideshow__caption">
          {{ slide.caption }}
        </p>
      </div>
    </div>
    <div class="slideshow__bullets" :class="{
      'slideshow__bullets--push': hasLinks,
      'slideshow__bullets--outside': bulletOutside
    }" v-if="bullets && swipable">
      <div v-for="(_, index) in ((numberOfSlides - viewport) * snapPoints + 1)" class="slideshow__bullet" :class="{
        'slideshow__bullet--active':
          index === -translation * viewport * snapPoints,
      }" :key="index"></div>
    </div>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'

const SWIPE_TOLERANCE = 0.1

export default {
  name: 'VSlideshow',

  components: {
    VMedia,
  },

  props: {
    slides: {
      type: [Array, Object],
      required: true,
    },
    gutters: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    demi: {
      type: Boolean,
      default: false,
    },
    bullets: {
      type: Boolean,
      default: true,
    },
    bulletOutside: {
      type: Boolean,
      default: false,
      required: false
    },
    ratio: {
      type: Number,
      default: 12 / 18,
    },
    slide: {
      type: Number,
      default: 0,
    },
    showMarginEffect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      swipeOrigin: null,
      translation: 0,
      viewport: 0,
      snapPoints: 0,
      wrapperWidth: 0,
      gutter: 0,
      showMargin: 0,
    }
  },

  created() {
    this.swipeMove = this.swipeMove.bind(this)
    this.swipeEnd = this.swipeEnd.bind(this)
    this.resize = this.resize.bind(this)
    this.continuousTranslation = 0
    this.absoluteTranslation = 0
    this.swipePosition = 0
    this.swipeDelta = 0
    this.swipeTime = 0
  },

  mounted() {
    addEventListener('resize', this.resize, { passive: true })
    this.resize()
    this.translation = this.bound(
      this.discretize(-this.slide / this.numberOfSlides / this.snapPoints),
    )

    this.continuousTranslation = this.translation
  },

  beforeDestroy() {
    removeEventListener('resize')
    removeEventListener('touchmove', this.swipeMove)
    removeEventListener('mousemove', this.swipeMove)
    removeEventListener('touchend', this.swipeEnd)
    removeEventListener('mouseup', this.swipeEnd)
  },

  watch: {
    slides() {
      this.resize()
    },
  },

  computed: {
    numberOfSlides() {
      return this.slides.reduce(
        (numberOfSlides, { slices = 1 }) => numberOfSlides + slices,
        0,
      )
    },

    swipable() {
      return !this.wrap && this.viewport < this.numberOfSlides
    },

    hasLinks() {
      return this.slides.some((slide) => slide.link || slide.share)
    },

    bulletIndex() {
      return -this.translation * this.viewport * this.snapPoints
    },
  },

  methods: {
    swipeStart(event) {
      const touch = event.type === 'touchstart'

      this.continuousTranslation = this.translation
      this.resize()

      if (
        (!touch && event.button !== 0) ||
        this.swipeTransition ||
        !this.swipable
      ) {
        return
      }

      if (this.showMarginEffect) this.showMargin = 1

      this.swipeDelta = 0
      this.swipeTime = Date.now()
      this.swipeOrigin = touch ? event.touches[0].clientX : event.clientX
      this.absoluteTranslation = this.wrapperWidth * this.translation

      addEventListener(touch ? 'touchmove' : 'mousemove', this.swipeMove)
      addEventListener(touch ? 'touchend' : 'mouseup', this.swipeEnd)
    },

    swipeMove(event) {
      const touch = event.type === 'touchmove'
      const swipePosition = touch ? event.touches[0].clientX : event.clientX
      const swipeDelta = swipePosition - this.swipePosition

      this.isActive = true;
      this.swipeDelta = swipeDelta
      this.swipePosition = swipePosition
      this.$refs.wrapper.style.transform = `translate3d(${this.bound(
        this.absoluteTranslation + this.swipePosition - this.swipeOrigin,
        this.wrapperWidth,
      )}px, 0, 0)`

      touch || event.preventDefault()
    },

    swipeEnd(event) {
      const delta = Date.now() - this.swipeTime
      const touch = event.type === 'touchend'
      const absoluteTranslation =
        this.absoluteTranslation + this.swipePosition - this.swipeOrigin

      const end = () => {
        this.$refs.wrapper.style.transform = ''
        this.swipeOrigin = null
        this.translation = this.bound(
          this.discretize(
            Math.max(-50, Math.min(50, this.swipeDelta)) * SWIPE_TOLERANCE +
            absoluteTranslation / this.wrapperWidth,
          ),
        )

        this.continuousTranslation = this.translation
      }

      if (delta >= 80) {
        end()
      } else {
        setTimeout(end, 80)
      }

      this.isActive = false;

      setTimeout(() => {
        this.showMargin = 0
      }, 300)

      this.$emit('onChangeSlide', Math.abs(this.translation))

      removeEventListener(touch ? 'touchmove' : 'mousemove', this.swipeMove)
      removeEventListener(touch ? 'touchend' : 'mouseup', this.swipeEnd)
    },

    bound(translation, factor = 1) {
      return Math.max(
        (1 - this.numberOfSlides / this.viewport) * factor,
        Math.min(0, translation),
      )
    },

    discretize(translation) {
      // return Math.round(translation * this.viewport) / this.viewport
      return (
        Math.round(translation * this.viewport * this.snapPoints) /
        this.viewport /
        this.snapPoints
      )
    },

    mediaRatio(slices) {
      // const width = (
      //   this.wrapperWidth -
      //   this.gutter * Math.max(this.viewport - 1, 0)
      //   ) / this.viewport

      // return this.ratio / width * (
      //   slices * width +
      //   Math.max(slices - 1, 0) * this.gutter
      // )

      // this.ratio * (slices + (Math.max(slices - 1, 0) * this.gutter) / width)

      return (
        this.ratio *
        (slices +
          (Math.max(slices - 1, 0) * this.viewport) /
          (this.wrapperWidth / this.gutter - Math.max(this.viewport - 1, 0)))
      )

      // Math.max(slices - 1, 0) / (
      //   this.wrapperWidth / this.gutter / this.viewport -
      //   Math.max(this.viewport - 1, 0) / this.viewport
      // )

      // return this.ratio * (slices - this.gutter / this.wrapperWidth * this.viewport)
    },

    resize() {
      const style = getComputedStyle(this.$el)
      this.gutter = parseFloat(style.getPropertyValue('--gutter'))
      this.viewport = parseFloat(style.getPropertyValue('--viewport'))
      this.snapPoints = parseFloat(style.getPropertyValue('--snap-points'))
      this.wrapperWidth = this.$refs.wrapper.offsetWidth
      this.translation = this.bound(this.discretize(this.continuousTranslation))
    },
  },
}
</script>

<style lang="sass">
.slideshow
  overflow: hidden
  position: relative
  --viewport: 1
  --snap-points: 1
  --overflow: 0
  --mute-slice: 0
  
  &--showmargin &__slide
      transform: translateX(-0.4vw)
      @media screen and (max-width: 768px)
        transform: translateX(-1vw)
  &--showmargin &__slide:last-child
      transform: translateX(0.4vw)
      @media screen and (max-width: 768px)
        transform: translateX(1vw)

  &__wrapper
    width: calc(100% + var(--overflow))
    display: flex
    left: calc(var(--overflow) / -2)
    position: relative
    transform: translateX(calc(var(--translation) * 100%))

    &--transition
      transition: .4s

  &__slide
    width: calc(var(--slices) / var(--viewport) * 100%)
    box-sizing: border-box
    position: relative
    transition: transform .4s
    flex:
      grow: 0
      shrink: 0
    display: flex

  &__media
    width: 100%
    --mute-offset: calc((100% - var(--gutter) * (var(--slices) - 1)) * var(--mute-slice) / var(--slices) + var(--mute-slice) * var(--gutter))

    &,
    img
      pointer-events: none
      user-select: none

    &--interactive
      pointer-events: auto

  &__caption
    width: 100%
    margin: auto
    padding: 0 calc(var(--gutter) / 2)
    box-sizing: border-box
    font:
      size: 10px
      weight: bold
    text:
      transform: uppercase
      align: center

  &__slices
    width: calc(100% - var(--gutter))
    height: 100%
    top: 0
    left: 50%
    display: flex
    position: absolute
    justify-content: space-evenly
    transform: translateX(-50%)

  &__slice
    background-color: #fff
    width: var(--gutter)
    height: 100%
    flex:
      grow: 0
      shrink: 0

  &__bullets
    position: absolute
    left: 50%
    bottom: 16px
    transform: translateX(-50%)
    display: flex
    // mix-blend-mode: difference

    &--push
      bottom: 32px

    &--outside
      position: relative
      left: 0
      bottom: 0
      transform: none
      width: 100%
      justify-content: center
      margin-top: 20px

  &__bullet
    background-color: transparent
    width: 6px
    height: 6px
    border-radius: 6px
    border: 1px solid black
    opacity: 0.8
    transition: .2s
    flex:
      grow: 0
      shrink: 0

    &:not(:last-of-type)
      margin-right: 12px

    &--active
      background-color: black
      opacity: 1

  &--gutters
    // margin: var(--gutter) 0
    padding: 0 calc(var(--gutter) / 2)

  &--gutters &__wrapper
    margin-bottom: calc(-1 * var(--gutter))

  &--gutters &__slide
    padding: 0 calc(var(--gutter) / 2)
    margin-bottom: var(--gutter)

  &--demi
    .slideshow__slide
      width: 50%
      padding: 0

    .slideshow__slide:first-child
      padding-right: calc(var(--innerwidthgutter) / 2)

    .slideshow__slide:last-child
      padding-left: calc(var(--innerwidthgutter) / 2)

  &--wrap &__wrapper
    flex-wrap: wrap
</style>
