<template>
  <div
    class="hero"
    :style="{ '--ratio': mediaRatio }"
  >
    <div class="hero__wrapper" ref="wrapper">
      <div class="hero__figure" ref="figure">
        <img
          class="hero__logo"
          :src="logo"
          alt="logo"
        />
        <v-media
          class="hero__media"
          :src="src"
          :srcset="srcset"
          :sizes="sizes"
          :ratio="mediaRatio"
          @load="resize"
           :media-link="mediaLink"
        />
      </div>
      <p class="hero__caption">
        {{ caption }}
      </p>
    </div>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'

const MEDIA_RATIO = 473 / 724
const FIGURE_RENDER_WIDTH = 570

export default {
  name: 'VHero',

  components: {
    VMedia
  },

  props: {
    src: {
      type: String,
      required: true
    },
    srcset: {
      type: String,
      default: ''
    },
    sizes: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      required: false
    },
    logo: {
      type: String,
      required: true,
    },
    mediaLink: {
      type: String,
      default: ''
    }
  },

  created() {
    this.resize = this.resize.bind(this)
    this.resizeTimeout = null
    this.mediaRatio = MEDIA_RATIO
  },

  mounted() {
    addEventListener('resize', this.resize, { passive: false })
    this.resize()
  },

  beforeDestroy() {
    removeEventListener('resize', this.resize)
  },

  methods: {
    // The figure has a constant render size and is then scaled to ensure perfect
    // logo / image alignment: we cannot trust the svg interpolation which slightly
    // offsets path coordinates depending on the rendering size
    resize(event) {
      clearTimeout(this.resizeTimeout)

      if (event) {
        this.resizeTimeout = setTimeout(() => {
          this.resize()
        }, 1)
      }

      this.$refs.figure.style.setProperty(
        '--scale',
        this.$refs.wrapper.offsetWidth / FIGURE_RENDER_WIDTH
      )

      this.$refs.wrapper.style.height = `${this.$refs.figure.getBoundingClientRect().height}px`
    }
  }
}
</script>

<style lang="sass">
.hero
  transform: translate3d(0,0,0)
  --margin-top: var(--gutter)
  --margin-bottom: calc(var(--gutter) * 2 + 20px)
  --height: calc((var(--vh) * 100) - var(--margin-top) - var(--margin-bottom))
  width: 100%
  margin: var(--margin-top) 0 var(--margin-bottom) 0
  min-height: var(--height)
  display: flex

  &__wrapper
    width: #{'min(calc(var(--ratio) * var(--height)) / 0.84, calc(100% - var(--gutter) * 2))'}
    min-width: 400px
    margin: auto
    position: relative

  &__figure
    width: 570px
    position: absolute
    transform: scale(var(--scale, 0))
      origin: top left

  &__logo
    width: 100%
    top: 50%
    position: absolute
    display: block
    z-index: 2
    transform: translateY(-50%)

  &__media
    width: 84%
    margin: 0 auto

  &__caption
    width: 100%
    top: 100%
    margin-top: var(--gutter)
    position: absolute
    white-space: pre-line
    font:
      size: 10px
      weight: bold
    text:
      align: center
      transform: uppercase

  @media screen and (max-width: 1023px)
    // Avoid overlapping header
    --margin-top: max(var(--gutter), 56px)
    &__wrapper
      min-width: 0
</style>
