<template>
  <div class="stripes">
    <h2 class="ft-condensed">{{ title }}</h2>
    <p>{{ subTitle }}</p>
  </div>
</template>

<script>
export default {
  name: "VStripes",
  props: {
    title: {
      type: String,
      default: false,
      required: true,
    },
    subTitle: {
      type: String,
      default: false,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass">
.stripes
    width: calc(100% - var(--guter)*2)
    margin: var(--gutter) auto
    margin-bottom: calc(var(--gutter)/2)
    border-top: 2px solid black
    border-bottom: 2px solid black
    padding: 7px 0
    margin-top: calc(var(--gutter)/2)
    @media screen and (min-width: 768px)
        padding: 13px 0

    h2, p
        text-align: center
        text-transform: uppercase
        line-height: 1em

    h2
        font-weight: bold
        line-height: 90%
        font-size: 11px
        @media screen and (min-width: 768px)
            font-size: 18px
            line-height: 22px

    p
        margin-top: 3px
        letter-spacing: -0.05em
        font-size: 7px
        @media screen and (min-width: 768px)
            font-size: 11px
</style>
