<template>
    <Transition name="slide">
    <div class="cookie-consent-banner" v-if="!hideBanner">
        <div class="cookie-consent-banner__inner">
            <div class="cookie-consent-banner__copy">
                <div class="cookie-consent-banner__header">THIS WEBSITE USES COOKIES</div>
                <div class="cookie-consent-banner__description">We use cookies to personalise content and ads, to
                    provide social media features and to analyse our traffic. We also share information about your use
                    of our site with our social media, advertising and analytics partners who may combine it with other
                    information that you’ve provided to them or that they’ve collected from your use of their services.
                    You consent to our cookies if you continue to use our website.</div>
            </div>

            <div class="cookie-consent-banner__actions">
                <button @click="hide(true)" class="cookie-consent-banner__cta">
                    OK
                </button>

                <button @click="hide(false)" class="cookie-consent-banner__cta cookie-consent-banner__cta--secondary">
                    DECLINE
                </button>
            </div>
        </div>
    </div>
    </Transition>
</template>
<script>
export default {
  name: 'VCookies',
  data() {
    return {
        hideBanner: true
    }
  },
  props: {
    gatag: {
      type: String,
      required: true,
      default: 'G-LGEV9F1QJN',
    },
  },
  mounted() {
    if (!localStorage.getItem('hideBanner')) {
      this.hideBanner = false;
    }

    if(localStorage.getItem('acept-ga') === 'true') {
        this.setGa();
    }
  },
  methods: {
    loadScript(src, id) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async setGa() {
        await this.loadScript('https://www.googletagmanager.com/gtag/js?id='+this.gatag, 'ga');
        // eslint-disable-next-line
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line
        // @ts-ignore
        function gtag(){ dataLayer.push(arguments); }
        // eslint-disable-next-line
        // @ts-ignore
        gtag('js', new Date());
        // eslint-disable-next-line
        // @ts-ignore
        gtag('config', this.gatag);
    },
    hide(accept) {

      console.log('click', accept)

        if(accept) {
            localStorage.setItem('acept-ga', 'true');
            this.setGa();
        }

        this.hideBanner = true;
        localStorage.setItem('hideBanner', 'true');
    }
  }
}
</script>
<style type="scss" scoped>

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s cubic-bezier(0.79,0.14,0.15,0.86), transform 0.35s cubic-bezier(0.79,0.14,0.15,0.86);
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(210px);
}

.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2147483645;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    filter: drop-shadow(0px 5px 30px rgba(0,0,0,0.5));
    padding: 10px;
    z-index: 9999;
    font-family: 'HelveticaNeue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cookie-consent-banner__inner {
    max-width: 960px;
    margin: 0 auto;
}

.cookie-consent-banner__copy {
    margin-bottom: 16px;
}

.cookie-consent-banner__actions {
    display: flex;
    justify-content: space-between;
}

.cookie-consent-banner__header {
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
}

.cookie-consent-banner__description {
    font-weight: normal;
    color: black;
    font-size: 10px;
    line-height: 15px;
    text-transform: uppercase;
}

.cookie-consent-banner__cta {
    box-sizing: border-box;
    display: inline-block;
    min-width: calc(50% - 10px);
    padding: 11px 13px;
    cursor: pointer;

    border: 1px solid #000;
    border-radius: 3px;

    background-color: #fff;

    color: #000;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
    font-size: 10px;
    padding-top: 10px;
}
.cookie-consent-banner__cta:visited {
    color: #000;
}

.cookie-consent-banner__cta--secondary {
    padding: 9px 13px;

    border: 1px solid black;

    background-color: black;
    color: #fff;
    padding-top: 12px;
}
.cookie-consent-banner__cta--secondary:visited {
    color: #fff;
}

</style>
