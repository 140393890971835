<template>
    <div class="mobileOrientationBlocker">
        <p>PLEASE ROTATE YOUR DEVICE</p>
        <p>THIS PAGE IS BEST VIEWED IN PORTRAIT ORIENTATION</p>
    </div>
</template>
<script>
export default {
  name: 'VHorizontalBlocker',
}
</script>
<style lang="sass">
.mobileOrientationBlocker
    display: flex
    width: 100vw
    height: calc(100 * var(--vh))
    flex-direction: column
    align-items: center
    justify-content: center
    visibility: hidden
    position: fixed
    top:0
    left: 0
    z-index: 20
    background-color: white

    @media screen and (orientation: landscape) and (max-width: 1024px)
        visibility: visible

    p
        margin: 0
        line-height: 1em
        font-family: 'HelveticaNeue'
        color: #000

        &:first-child
            margin-bottom: 5px
            font-size: 18px
            font-weight: 700
            font-family: 'HelveticaNeueBold'
        

        &:last-child
            font-size: 14px

.is-focused .mobileOrientationBlocker
    display: none
</style>