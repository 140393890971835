<template>
  <div class="dualThreeColumns">
    <div v-for="(slide, i) in inverted ? slides.reverse() : slides"  :key="i" :class="`dualThreeColumns__slide dualThreeColumns__slide__${i}`">
      <v-media
        class="cover__media dualThreeColumns__media"
        :src="slide.src"
        :lazy="slide.lazy"
        :srcset="slide.srcset"
        :sizes="slide.sizes"
        :ratio="slide.ratio"
        :media-link="slide.mediaLink"
      />
    </div>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'
export default {
  name: 'VDuoThreeColumns',
  components: {
    VMedia
  },
  props: {
    slides: {
      type: [Array, Object],
      required: true
    },
    gutters: {
      type: Boolean,
      default: false
    },
    mediaLink: {
      type: String,
      default: ''
    },
    inverted: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="sass">
.dualThreeColumns
  transform: translate3d(0,0,0)
  display: grid
  grid-template-columns: 2fr 1fr
  grid-auto-rows: 1fr
  align-content: start
  grid-column-gap: 32px
  grid-row-gap: 0px
  width: 100%
  box-sizing: border-box
  overflow: hidden
  background-color: white
  position: relative
  z-index: 10

  @media screen and (max-width: 768px)
    grid-template-columns: 1fr
    grid-row-gap: 16px

  &__slide
    align-self: start
    height: 100%

  &__media
    height: 100%
    a
      padding: 0 !important
      height: 100% !important
    img
      position: relative !important
      height: 100% !important
      aspect-ratio: auto !important
</style>
