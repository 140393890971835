var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"cover",staticStyle:{"position":"relative","z-index":"3"}},[(_vm.logoImage)?_c('img',{staticClass:"cover__logoImage",style:({
    '--logo-image-width-small': _vm.logoImageWidth[0],
    '--logo-image-width-large': _vm.logoImageWidth[1]
  }),attrs:{"src":_vm.logoImage,"alt":"Logo SL"}}):_vm._e(),_c('div',{staticClass:"cover",class:{
    'cover--fit': _vm.ratio === -1,
    'cover--gutters': _vm.gutters,
    'cover--innerspace': _vm.innerspace,
    'cover--sticky': _vm.sticky,
  }},[_c('v-media',{staticClass:"cover__media",class:{'cover__media--fullHeight': _vm.fullHeight},attrs:{"src":_vm.src,"lazy":_vm.lazy,"fetchPriority":_vm.fetchPriority,"srcset":_vm.srcset,"sizes":_vm.sizes,"ratio":_vm.ratio,"poster":_vm.poster,"contain":_vm.contain,"muteDisplay":_vm.muteDisplay,"media-link":_vm.mediaLink}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }