<template>
  <footer class="footer">
    <div class="footer__wrapper footer__wrapper--large">
      <div class="footer__row">
        <ul class="footer__column footer__column--ghost"></ul>
        <ul class="footer__column">
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en" target="_blank">
              VISIT YSL.COM
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/displayname-faq" target="_blank">
              Client service
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/displayname-faq?faqCategory=FAQShipping" target="_blank">
              Shipping
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/displayname-faq?faqCategory=FAQReturns" target="_blank">
              Returns
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/contactus" target="_blank">
              Email
            </a>
            <a class="footer__link" href="tel:+442033185913">
              +44 20 33 18 59 13
            </a>
          </li>
        </ul>
        <ul class="footer__column">
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://kering.wd3.myworkdayjobs.com/SaintLaurent" target="_blank">
              Career
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/legal-notices/name/terms-and-conditions-use.html" target="_blank">
              Legal notices
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/legal-notices/name/web-accessibility-content-page.html" target="_blank">
              Accessibility
            </a>
          </li>
        </ul>
        <ul class="footer__column footer__column--margin">
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.facebook.com/ysl" target="_blank">
              Facebook
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.twitter.com/ysl" target="_blank">
              Twitter
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.instagram.com/ysl" target="_blank">
              Instagram
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.youtube.com/ysl" target="_blank">
              Youtube
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.tiktok.com/@ysl" target="_blank">
              TikTok
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link footer__link--arrow" rel="noopener" href="https://www.ysl.com/en-en/networks/name/linedrawer.html" target="_blank">
              Line
            </a>
          </li>
           <li class="footer__item">
            <a class="footer__link footer__link--arrow" rel="noopener" href="https://www.ysl.com/en-en/networks/name/wechatdrawer.html" target="_blank">
              Wechat
            </a>
          </li>
        </ul>
        <ul class="footer__column footer__column--disclaimer">
          <li class="footer__item">
            <span class="footer__link">
              Retouched photographs
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer__wrapper footer__wrapper--small">
      <div class="footer__row">
        <div></div>
        <ul class="footer__column footer__column--right footer__column--margins">
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en" target="_blank">
              VISIT YSL.COM
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/displayname-faq" target="_blank">
              Client service
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/legal-notices/name/terms-and-conditions-use.html" target="_blank">
              Legal notices
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link" rel="noopener" href="https://www.ysl.com/en-en/legal-notices/name/web-accessibility-content-page.html" target="_blank">
              Accessibility
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__row footer__row--networks">
        <div class="footer__column">
          <span class="footer__item">Follow us</span>
        </div>
        <ul class="footer__column footer__column--network-icons">
          <li class="footer__item">
            <a class="footer__link footer__link--facebook" rel="noopener" href="https://www.facebook.com/ysl" target="_blank">
              Facebook
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link footer__link--twitter" rel="noopener" href="https://www.twitter.com/ysl" target="_blank">
              Twitter
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link footer__link--instagram" href="https://www.instagram.com/ysl" rel="noopener" target="_blank">
              Instagram
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link footer__link--youtube" href="https://www.youtube.com/ysl" rel="noopener" target="_blank">
              Youtube
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link footer__link--tiktok" href="https://www.tiktok.com/@ysl" rel="noopener" target="_blank">
              TikTok
            </a>
          </li>
          <li class="footer__item">
            <a class="footer__link footer__link--line" rel="noopener" href="https://www.ysl.com/en-en/networks/name/linedrawer.html" target="_blank">
              Line
            </a>
          </li>
           <li class="footer__item">
            <a class="footer__link footer__link--wechat" rel="noopener" href="https://www.ysl.com/en-en/networks/name/wechatdrawer.html" target="_blank">
              Wechat
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__row">
        <div class="footer__column">
          <div class="footer__item">
            <span class="footer__link">
              Retouched photographs
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'VFooter'
}
</script>

<style lang="sass">
.footer
  position: relative
  z-index: 10
  background-color: white
  transform: translate3d(0,0,0)

  &__wrapper
    &--large
      border-top: 1px solid #eee

    &--small
      display: none

  &__row
    display: flex
    padding: 30px
    justify-content: space-between
    border-bottom: 1px solid #eee

  &__wrapper--large &__row:last-of-type
    border-bottom: none

  &__column
    width: 20%

    &--right
      text-align: right

    &--disclaimer
      min-width: 185px

  &__item
    height: 11px
    line-height: 11px
    font-size: 10px
    text-transform: uppercase

  &__column--margin &__item
    padding: 0 15px

  &__column--network-icons &__item
    margin: 0 11.5px
    display: inline-block
    font-size: 0

    &:last-of-type
      margin-right: 0

  &__link
    color: #000
    text-decoration: none

    &--arrow::after
      content: '\F106'
      height: 8px
      font:
        family: 'icons'
        size: 8px
      line-height: 8px
      padding-left: 7px
      display: inline-block

    &--facebook::before
      content: '\F117'

    &--twitter::before
      content: '\F132'

    &--instagram::before
      content: '\F11C'

    &--youtube::before
      content: '\F139'

    &--tiktok::before
      content: '\F12F'

    &--line::before
      content: '\F11F'

    &--wechat::before
      content: '\F136'

  &__column--network-icons &__link
    color: transparent
    font-family: 'icons'

    &::before
      font-size: 11px
      color: #000

  @media screen and (max-width: 1439px)
    &__row
      padding: 20px

    &__column
      width: auto

      &--ghost
        width: 196px

  @media screen and (max-width: 1023px)
    &__wrapper
      &--large
        display: none

      &--small
        padding-bottom: 7px
        display: block

    &__column--margins
      margin-bottom: 20px

    &__item
      height: 18px
      line-height: 18px

    &__row
      padding: 15px 20px

      &--networks
        padding-bottom: 13px

    &__item
      font-size: 11px
</style>
